import { Row, Col, size, Container } from '@everlywell/leaves';
import SectionWrapper from 'components/SectionWrapper';
import { GatsbyImage as Img, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import { animated as A, useSpring, config } from 'react-spring';
/** local imports */
import { CTA } from 'utils/types';

import { RefsForAnimationContext } from '../../../templates/enterprise';
import * as S from './styles';

export type ContentWithImage = {
  heading: string;
  description: string;
  image: IGatsbyImageData;
  imageAlt: string;
  cta?: CTA;
};

export interface WhatToExpectSectionProps {
  title: string;
  headline: string;
  mainCta?: CTA;
  listOfContent: ContentWithImage[];
  backgroundColor: 'Primary' | 'Secondary';
  uid?: string;
}

export const WhatToExpectSection: React.FC<WhatToExpectSectionProps> = ({
  title,
  headline,
  mainCta,
  listOfContent,
  backgroundColor,
  uid = 'what-to-expect-section',
}) => {
  // TODO: add 150px offset supported by legacy hook if needed
  const [wteCardsEl, inView] = useInView({ triggerOnce: true });

  const { animation3, animation4 } = useContext(RefsForAnimationContext);

  // triggered from useChain in enterprise template
  const titleTransition = useSpring({
    from: { opacity: 0, transform: `translateY(${size.xl1}px)` },
    to: { opacity: 1, transform: 'translateY(0)' },
    ref: animation3,
    config: config.slow,
  });
  // triggered from useChain in enterprise template
  const displayTransition = useSpring({
    from: { opacity: 0, transform: `translateY(${size.xl2}px)` },
    to: { opacity: 1, transform: 'translateY(0)' },
    ref: animation4,
    config: config.slow,
  });
  // triggered from scrolling into view
  const cardsTransition = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : `translateY(${size.xl3}px)`,
    config: config.slow,
  });

  return (
    <SectionWrapper
      id={uid}
      backgroundColor={backgroundColor}
      data-testid={uid}
    >
      <Container>
        <Row center="xs">
          <Col xs={12} lg={10}>
            <A.div style={titleTransition}>
              <S.SectionTitle text={title} />
            </A.div>
          </Col>
        </Row>
        <Row center="xs">
          <Col xs={12} lg={10}>
            <A.div style={displayTransition}>
              <S.Headline>{headline}</S.Headline>
            </A.div>
          </Col>
        </Row>
        <Row center="xs" around="lg">
          <Col xs={10} sm={12} lg={10}>
            <S.InfoBlockContainer ref={wteCardsEl}>
              {listOfContent.length
                ? listOfContent.map((content, index) => (
                    <S.AnimatedBlockContainer
                      key={index}
                      style={cardsTransition}
                    >
                      <S.InfoBlock
                        title={content.heading}
                        image={
                          <Img
                            image={content.image}
                            style={{ width: '100%', height: '100%' }}
                            alt={content.imageAlt}
                          ></Img>
                        }
                        imageAlt={content.imageAlt}
                        content={content.description}
                        size="large"
                      />
                    </S.AnimatedBlockContainer>
                  ))
                : null}
            </S.InfoBlockContainer>
          </Col>
        </Row>
        {mainCta ? (
          <S.CtaContainer>
            <S.Button appearance="secondary" href={mainCta.url}>
              {mainCta.text}
            </S.Button>
          </S.CtaContainer>
        ) : null}
      </Container>
    </SectionWrapper>
  );
};

export default WhatToExpectSection;
