import {
  InfoBlock as BaseInfoBlock,
  size,
  mediaQueries,
  typography,
  colors,
  H2,
  Button as BaseButton,
} from '@everlywell/leaves';
import { animated as A } from 'react-spring';
import styled from 'styled-components';

import { SectionTitle as RawSectionTitle } from '../../Enterprise/SectionTitle';

const SectionTitle = styled(RawSectionTitle)`
  color: ${colors.green5};
  text-align: center;
`;

const Headline = styled(H2)`
  margin: ${size.xs1}px 0 ${size.xl1}px;

  ${mediaQueries.forTabletHorizontalUp} {
    margin: 0 0 ${size.xl4}px;
  }
`;

const InfoBlock = styled(BaseInfoBlock)`
  margin-top: ${size.md}px;
  margin-bottom: ${size.xl2}px;

  ${mediaQueries.forTabletHorizontalUp} {
    justify-content: space-between;
  }
  p {
    font-weight: ${typography.weight.book};
    font-size: ${typography.bodyTextSmall};
  }
  div:nth-child(1) {
    height: unset;
    background-color: unset;
  }
`;

const AnimatedBlockContainer = styled(A.div)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const InfoBlockContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;

  ${mediaQueries.forTabletVerticalUp} {
    margin: 0 -${size.md}px;

    ${AnimatedBlockContainer} {
      width: 50%;
      padding: 0 ${size.md}px;
    }
  }

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
    align-items: start;
    justify-content: center;
  }

  ${mediaQueries.forDesktopUp} {
    /* Split items in a grid of 3 items per row */
    ${AnimatedBlockContainer} {
      width: 33.33%;
      padding: 0 ${size.lg}px;
      align-items: center;

      div:nth-child(1) {
        flex-direction: column;
      }
      div:nth-child(2) {
        text-align: center;
      }
      h3 {
        margin-top: ${size.md}px;
        margin-bottom: ${size.xs1}px;
      }
    }

    /* Split items in a grid of 2 items per row ONLY when there are 4 items. Should snap back to 3/2 to accommodate 5 items and 3/3 for 6 (and so on).
    The interpolation tagging sc-selector was added below to fix styling lint error: https://styled-components.com/docs/tooling#interpolation-tagging */
    ${
      /* sc-selector */ AnimatedBlockContainer
    }:first-child:nth-last-child(3n + 1),
    ${
      /* sc-selector */ AnimatedBlockContainer
    }:first-child:nth-last-child(3n + 1) ~
    ${AnimatedBlockContainer} {
      width: 50%;
      align-items: flex-start;
      margin-bottom: 56px;
      div:nth-child(1) {
        flex-direction: row;
      }
      div:nth-child(2) {
        text-align: left;
        margin-left: ${size.xl1}px;
      }
    }
  }
`;

const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled(BaseButton)`
  border-color: ${colors.green5};
  color: ${colors.green5};
  &:hover,
  &:focus {
    background-color: ${colors.green5};
  }
`;

export {
  SectionTitle,
  Headline,
  InfoBlockContainer,
  AnimatedBlockContainer,
  InfoBlock,
  CtaContainer,
  Button,
};
