import WhatToExpectSection from 'components/Homepage/WhatToExpectSection';
import React from 'react';
import { ContentWithImage, CTA } from 'utils/types';

export interface WhatToExpectContainerProps {
  content: {
    title: string;
    headline: string;
    mainCta: CTA;
    listOfContentWithImages: ContentWithImage[];
    backgroundColor: 'Primary' | 'Secondary';
    slug?: string;
  };
}

export const WhatToExpectContainer: React.FC<WhatToExpectContainerProps> = ({
  content,
}) => {
  if (!content) {
    return null;
  }

  const {
    title,
    headline,
    mainCta,
    listOfContentWithImages,
    backgroundColor,
    slug,
  } = content;
  const formattedListOfContent = listOfContentWithImages.map((item) => ({
    heading: item.heading,
    description: item.description?.description || '',
    image: item.image.gatsbyImageData,
    imageAlt: item.image.description || '',
  }));

  return (
    <WhatToExpectSection
      title={title}
      headline={headline}
      mainCta={mainCta}
      listOfContent={formattedListOfContent}
      backgroundColor={backgroundColor || 'Primary'}
      uid={slug}
    />
  );
};

export default WhatToExpectContainer;
